import {ApiQueryParams} from '@core/models/api';
import {firstValueFrom} from 'rxjs';
import {CrudEndPoint} from '@core/const';
import {Payload} from '@core/models/payload';
import {IdentifierReference} from '@core/models/identifier-reference';
import {QueryApiService} from "@core/crud/query-api.service";

export abstract class CrudApiService<T, R extends IdentifierReference> extends QueryApiService<T, R> {

  protected constructor(endpoint: CrudEndPoint) {
    super(endpoint);
  }

  async query(page: number, size: number, params?: ApiQueryParams): Promise<Payload<T>> {
    const queryParams: ApiQueryParams = {
      ...this.getDefaultParams(),
      page: page,
      size: size,
      ...params
    }
    return (await this.getWithParams(queryParams)) as Payload<T>;
  }

  async get(id: number): Promise<T> {
    return (await firstValueFrom(this.apiService.get(`${this.endPoint}/${id}`))) as T;
  }

  async getReference(id: number): Promise<R> {
    return (await firstValueFrom(this.apiService.get(`${this.endPoint}/reference/${id}`, undefined, undefined, undefined, false))) as R;
  }

  async post(dto: T): Promise<T> {
    return await firstValueFrom(this.apiService.post<T, T>(this.endPoint, dto));
  }

  async put<T>(id: number, dto: T): Promise<T> {
    return await firstValueFrom(
      this.apiService.put<T, T>(`${this.endPoint}/${id}`, dto)
    )
  }

  async delete(id: number): Promise<boolean> {
    return await firstValueFrom(
      this.apiService.delete(`${this.endPoint}/${id}`)
    )
  }

  getEndPoint(): CrudEndPoint {
    return this.endPoint;
  }

  async existsElement(searchBy: string, searchValue: string, id?: number, touched?: boolean): Promise<boolean> {
    const queryParams: ApiQueryParams = {};
    if (searchValue) {
      queryParams.equals = {[searchBy]: searchValue};
    } else {
      return false;
    }
    if (id) {
      queryParams.notEquals = {id};
    }
    if (!touched) {
      return false;
    }
    const result = await this.queryReference(0, 1, queryParams);
    return result.totalCount > 0;
  }
}
